import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'
import FileHandler from '../layout/file-handler/fileHandler'

class TasteAdviceNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Taste advices', path: '/taste-advices'},
            tempBanner: null,
            tempCover: null,
            tasteAdvice: {
                name: {
                    en: null,
                    zh: null
                },
                description: {
                    en: null,
                    zh: null
                },
                content: []
            }
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    createCorrectBannerUrl = (image) => {
        let urlPrefix = 'storage/taste-advice-banner/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    // Banner image
    showBannerImage = (file, fileObj, type, index) => {
        var tasteAdvice = this.state.tasteAdvice
        tasteAdvice.banner = this.createCorrectBannerUrl(fileObj)

        this.setState(previousState => ({
            tasteAdvice,
            tempBanner: tasteAdvice.banner
        }))
    }

    removeBannerImage = () => {
        this.setState(previousState => ({
            tasteAdvice: {
                ...this.state.tasteAdvice,
                banner: null
            },
            tempBanner: null
        }))
    }


    createCorrectCoverUrl = (image) => {
        let urlPrefix = 'storage/taste-advice-cover/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    // Cover image
    showCoverImage = (file, fileObj, type, index) => {
        var tasteAdvice = this.state.tasteAdvice
        tasteAdvice.cover = this.createCorrectCoverUrl(fileObj)

        this.setState(previousState => ({
            tasteAdvice,
            tempCover: tasteAdvice.cover
        }))
    }

    removeCoverImage = () => {
        this.setState(previousState => ({
            tasteAdvice: {
                ...this.state.tasteAdvice,
                cover: null
            },
            tempCover: null
        }))
    }


    // Content block
    addContentBlock = () => {
        const content = this.state.tasteAdvice.content

        const newBlock = {
            title: {
                en: '',
                zh: ''
            },
            description: {
                en: '',
                zh: ''
            },
        }

        const newBlocks = [...content, newBlock]

        this.setState({
            tasteAdvice: {
                ...this.state.tasteAdvice,
                content: newBlocks
            }
        })
    }

    removeContentBlock = (index) => {
        var r = window.confirm("Are you sure you want to delete this block?");
        if (r === false) return

        let content = this.state.tasteAdvice.content

        content.splice(index, 1)

        this.setState(prevState => ({
            ...prevState,
            content
        }))
    }

    handleChange = (e, element, subElement, lang, index = 0) => {
        var newValue = e
        let {tasteAdvice} = this.state

        if (element === 'content') {
            tasteAdvice[element][index][subElement][lang] = newValue
        } else {
            if (lang == '') {
                tasteAdvice[element][subElement] = newValue
            } else {
                tasteAdvice[element][subElement][lang] = newValue
            }
        }

        this.setState(previousState => ({
            ...previousState,
            tasteAdvice
        }))
    }

    updateField(e, key) {
        this.setState({
            tasteAdvice: {
                ...this.state.tasteAdvice,
                [key]: e.target.value
            }
        })
    }

    saveEntity = () => {
        this.setState({loading: true})

        if (!this.state.tasteAdvice.name.en || !this.state.tasteAdvice.name.zh) {
            toast.error('Taste advice\'s name can not be empty')
            this.setState({loading: false})
            return
        } else if (!this.state.tasteAdvice.banner) {
            toast.error('Taste advice\'s banner can not be empty')
            this.setState({loading: false})
            return
        } else if (!this.state.tasteAdvice.cover) {
            toast.error('Taste advice\'s cover can not be empty')
            this.setState({loading: false})
            return
        }

        let banner = this.state.tasteAdvice.banner
        banner.uri = banner.uri.replace('storage/taste-advice-banner/', '')

        let cover = this.state.tasteAdvice.cover
        cover.uri = cover.uri.replace('storage/taste-advice-cover/', '')

        this.setState({
            tasteAdvice: {
                ...this.state.tasteAdvice,
                banner: banner,
                cover: cover,
            }
        })

        const newTasteAdvice = this.state.tasteAdvice

        API.create('taste-advices', newTasteAdvice).then((result) => {
            toast.success('Taste advice has been created')
            this.props.history.push('/taste-advices')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Add taste advice'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <form onSubmit={(e) => {
                        this.onSubmit(e, () => this.saveEntity(this.state.tasteAdvice))
                    }} className="custom-form">
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/taste-advices'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block above">
                            <div className="fields container clearfix">
                                <h3>Information</h3>
                                <div className="field" key="nameEn">
                                    <label>Name in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'en', '')}
                                           value={this.state.tasteAdvice?.name?.en || ""}/>
                                </div>
                                <div className="field" key="nameZh">
                                    <label>Name in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'zh', '')}
                                           value={this.state.tasteAdvice?.name?.zh || ""}/>
                                </div>
                                <div className="field" key="banner">
                                    <div className="file-handler-container">
                                        {this.state.tempBanner &&
                                        <FileHandler
                                            image={this.state.tempBanner.uri}
                                            name={this.state.tempBanner.name}
                                            removeImage={(type) => this.removeBannerImage(type)}
                                            type={'images'}
                                            label={'Banner'}
                                            fileType={'images'}
                                            path={'taste-advice-banner'}
                                        />
                                        }

                                        {!this.state.tempBanner &&
                                        <FileHandler
                                            showImage={this.showBannerImage}
                                            type={'images'}
                                            label={'Banner'}
                                            fileType={'images'}
                                            path={'taste-advice-banner'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>
                                <div className="field" key="cover">
                                    <div className="file-handler-container">
                                        {this.state.tempCover &&
                                        <FileHandler
                                            image={this.state.tempCover.uri}
                                            name={this.state.tempCover.name}
                                            removeImage={(type) => this.removeCoverImage(type)}
                                            type={'images'}
                                            label={'Cover'}
                                            fileType={'images'}
                                            path={'taste-advice-cover'}
                                        />
                                        }

                                        {!this.state.tempCover &&
                                        <FileHandler
                                            showImage={this.showCoverImage}
                                            type={'images'}
                                            label={'Cover'}
                                            fileType={'images'}
                                            path={'taste-advice-cover'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>

                                <div className="long-field" key="descriptionEn">
                                    <label>Description in English</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'description', 'en', '')}
                                           value={this.state.tasteAdvice?.description?.en || ""}/>
                                </div>
                                <div className="long-field" key="descriptionZh">
                                    <label>Description in Chinese</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'description', 'zh', '')}
                                           value={this.state.tasteAdvice?.description?.zh || ""}/>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Content</h3>

                                {
                                    Object.keys(this.state.tasteAdvice.content).map(index => {
                                        return (
                                            <div className="fields container clearfix">
                                                <div>
                                                    <p style={{
                                                        textDecoration: 'underline'
                                                    }}>{parseInt(index) + 1}. Block {parseInt(index) + 1}
                                                    </p>
                                                    <label style={{
                                                        textDecorationLine: 'underline',
                                                        color: '#2196F3',
                                                        position: 'absolute',
                                                        right: '32px',
                                                        cursor: 'pointer'
                                                    }}
                                                           onClick={() => {
                                                               this.removeContentBlock(index)
                                                           }}
                                                    >Delete
                                                    </label>
                                                </div>

                                                <div className="field" key="titleEn">
                                                    <label>Title in English</label>
                                                    <input type="text"
                                                           onChange={(e) => this.handleChange(e.target.value, 'content', 'title', 'en', index)}
                                                           value={this.state.tasteAdvice?.content[index].title.en || ""}/>
                                                </div>

                                                <div className="field" key="titleZh">
                                                    <label>Title in Chinese</label>
                                                    <input type="text"
                                                        onChange={(e) => this.handleChange(e.target.value, 'content', 'title', 'zh', index)}
                                                        value={this.state.tasteAdvice?.content[index].title.zh || ""}/>
                                                </div>

                                                <div className="long-field" key="descriptionEn">
                                                    <label>Description in English</label>
                                                    <textarea type="text"
                                                        onChange={(e) => this.handleChange(e.target.value, 'content', 'description', 'en', index)}
                                                        value={this.state.tasteAdvice?.content[index].description.en || ""}
                                                    />
                                                </div>

                                                <div className="long-field" key="descriptionZh">
                                                    <label>Description in Chinese</label>
                                                    <textarea type="text"
                                                        onChange={(e) => this.handleChange(e.target.value, 'content', 'description', 'zh', index)}
                                                        value={this.state.tasteAdvice?.content[index].description.zh || ""}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="buttons">
                                    <button className="blue add clearfix"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.addContentBlock()
                                            }}>
                                        Add new block
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Content>
            </>
    }
}

export default TasteAdviceNew