import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'

class AppellationList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {},
            appellations: {
                columns: [
                    {
                        label: 'Appellation name in English',
                        key: 'nameEn'
                    },
                    {
                        label: 'Appellation name in Chinese',
                        key: 'nameZh'
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/appellations/' + id + '/edit')
                        }
                    }],
                entities: [],
                filters: [],
                actions: [{
                    classList: 'blue add',
                    label: 'New appellation',
                    onClick: () => this.props.history.push('/appellations/new')
                }],
                selectedItems: [],
                multiSelect: false,
                // onRowClick: (entity) => {
                // 	this.props.history.push('/appellations/' + entity.id + '/edit')
                // }
            }
        }
    }

    componentDidMount() {
        API.getAppellations(this.state.filters).then(entities => {
            entities.forEach(entity => {
                if (entity.name) {
                    if (entity.name.en) {
                        entity.nameEn = entity.name.en
                    } else {
                        entity.nameEn = '-'
                    }

                    if (entity.name.zh) {
                        entity.nameZh = entity.name.zh
                    } else {
                        entity.nameZh = '-'
                    }
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                appellations: {...this.state.appellations, entities: entities}
            })
        })
    }

    loadEntities() {
        API.getAppellations(this.state.filters).then(entities => {
            entities.forEach(entity => {
                if (entity.name) {
                    if (entity.name.en) {
                        entity.nameEn = entity.name.en
                    } else {
                        entity.nameEn = '-'
                    }

                    if (entity.name.zh) {
                        entity.nameZh = entity.name.zh
                    } else {
                        entity.nameZh = '-'
                    }
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                appellations: {...this.state.appellations, entities: entities}
            })
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Appellations'}/>
                <Content>
                    <Table settings={this.state.appellations}/>
                </Content>
            </>
    }
}

export default AppellationList
