import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'
import FileHandler from '../layout/file-handler/fileHandler'

class GrapeEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Grapes', path: '/grapes'},
            fields: {
                label: {label: 'Grape name', type: 'text', required: true},
            },
            grape: null
        }
    }

    createCorrectBannerUrl = (image) => {
        let urlPrefix = 'storage/grape-banner/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    componentDidMount() {
        Promise.all([
            API.getAppellations(),
            API.getGrape(this.props.match.params.grapeId)
        ]).then(([appellations, grape]) => {
            var options = []
            options.push({key: '', label: '---Choose---'})
            appellations.forEach(appellation => {
                let option = {}
                option.key = appellation.id
                option.label = appellation.name.en

                options.push(option)
            })
            var bannerImage = {}
            var coverImage = {}

            if (grape.banner) {
                bannerImage = this.createCorrectBannerUrl(grape.banner)
            }

            if (grape.cover) {
                coverImage = this.createCorrectCoverUrl(grape.cover)
            }

            this.setState({
                loading: false,
                grape: grape,
                tempBanner: bannerImage,
                tempCover: coverImage,
                appellations: grape.appellations ? grape.appellations : [],
                appellationOptions: options,
            })
        })
    }

    // Banner image
    showBannerImage = (file, fileObj, type, index) => {
        var grape = this.state.grape
        grape.banner = this.createCorrectBannerUrl(fileObj)

        this.setState(previousState => ({
            grape,
            tempBanner: grape.banner
        }))
    }

    removeBannerImage = () => {
        this.setState(previousState => ({
            grape: {
                ...this.state.grape,
                banner: null
            },
            tempBanner: null,
        }))
    }

    createCorrectCoverUrl = (image) => {
        let urlPrefix = 'storage/grape-cover/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    // Cover image
    showCoverImage = (file, fileObj, type, index) => {
        var grape = this.state.grape
        grape.cover = this.createCorrectCoverUrl(fileObj)

        this.setState(previousState => ({
            grape,
            tempCover: grape.cover
        }))
    }

    removeCoverImage = () => {
        this.setState(previousState => ({
            grape: {
                ...this.state.grape,
                cover: null
            },
            tempCover: null,
        }))
    }

    handleChange = (e, element, subElement, lang, index = 0) => {
        var newValue = e
        let {grape} = this.state

        if (lang == '') {
            grape[element][subElement] = newValue
        } else {
            grape[element][subElement][lang] = newValue
        }

        this.setState(previousState => ({
            ...previousState,
            grape
        }))
    }

    updateField(e, key) {
        this.setState({
            grape: {
                ...this.state.grape,
                [key]: e.target.value
            }
        })
    }

    updateArray(e, key, index) {
        if (key == 'appellations') {
            let found = false
            this.state.appellations.forEach(appellation => {
                if(appellation == e.target.value)
                    found = true
            })
            if (found)
                return toast.error('Appellation already added')
        }

        let tempArray = this.state[key]
        tempArray[index] = e.target.value
        this.setState({
            grape: {
                ...this.state.grape,
                [key]: tempArray
            }
        })
    }

    newAppellation = () => {
        this.setState({...this.state, appellations: [...this.state.appellations, '']})
    }

    removeAppellation = (index) => {
        const appellations = this.state.appellations
        appellations.splice(index, 1)
        this.setState({...this.state, appellations})
    }

    saveEntity = (updatedEntity) => {
        this.setState({loading: true})

        if (!this.state.grape.name?.en || !this.state.grape.name?.zh) {
            toast.error('Grape\'s name can not be empty')
            this.setState({loading: false})
            return
        } else if (!updatedEntity.banner) {
            toast.error('Grape\'s banner can not be empty')
            this.setState({loading: false})
            return
        } else if (!updatedEntity.cover) {
            toast.error('Grape\'s cover can not be empty')
            this.setState({loading: false})
            return
        }

        let bannerUri = updatedEntity.banner.uri.replace('storage/grape-banner/', '')
        updatedEntity.banner.uri = bannerUri

        let coverUri = updatedEntity.cover.uri.replace('storage/grape-cover/', '')
        updatedEntity.cover.uri = coverUri

        // Remove empty appellation
        const newAppellations = []
        this.state.grape.appellations.map(appellation => {
            if (appellation && appellation != '') {
                newAppellations.push(appellation)
            }
        })
        updatedEntity.appellations = newAppellations

        API.update('grapes', this.props.match.params.grapeId, updatedEntity).then(() => {
            toast.success('Grape has been updated')
            this.props.history.push('/grapes')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    deleteEntity = () => {
        var r = window.confirm("Are you sure you want to delete this grape?");
        if (r === false) return

        this.setState({loading: true})
        API.deleteGrape(this.props.match.params.grapeId).then(() => {
            toast.success('Grape was successfully deleted')
            this.props.history.push('/grapes')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Edit grape'}/>
                <Content>
                    <form onSubmit={(e) => {
                        this.onSubmit(e, () => this.saveEntity(this.state.grape))
                    }} className="custom-form grape">
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/grapes'))}>Cancel
                                </button>
                                <button className="blue">Save grape</button>
                            </div>
                        </div>

                        <div className="block above">
                            <div className="fields container clearfix">
                                <h3>Information</h3>
                                <div className="field" key="nameEn">
                                    <label>Name in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'en', '')}
                                           value={this.state.grape?.name?.en || ""}/>
                                </div>
                                <div className="field" key="nameZh">
                                    <label>Name in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'zh', '')}
                                           value={this.state.grape?.name?.zh || ""}/>
                                </div>
                                <div className="field" key="banner">
                                    <div className="file-handler-container">
                                        {this.state.tempBanner &&
                                        <FileHandler
                                            image={this.state.tempBanner.uri}
                                            name={this.state.tempBanner.name}
                                            removeImage={(type) => this.removeBannerImage(type)}
                                            type={'images'}
                                            label={'Banner'}
                                            fileType={'images'}
                                            path={'grape-banner'}
                                        />
                                        }

                                        {!this.state.tempBanner &&
                                        <FileHandler
                                            showImage={this.showBannerImage}
                                            type={'images'}
                                            label={'Banner'}
                                            fileType={'images'}
                                            path={'grape-banner'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>
                                <div className="field" key="cover">
                                    <div className="file-handler-container">
                                        {this.state.tempCover &&
                                        <FileHandler
                                            image={this.state.tempCover.uri}
                                            name={this.state.tempCover.name}
                                            removeImage={(type) => this.removeCoverImage(type)}
                                            type={'images'}
                                            label={'Cover'}
                                            fileType={'images'}
                                            path={'grape-cover'}
                                        />
                                        }

                                        {!this.state.tempCover &&
                                        <FileHandler
                                            showImage={this.showCoverImage}
                                            type={'images'}
                                            label={'Cover'}
                                            fileType={'images'}
                                            path={'grape-cover'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>

                                <div className="long-field" key="descriptionEn">
                                    <label>Description in English</label>
                                    <textarea type="text"
                                              onChange={(e) => this.handleChange(e.target.value, 'description', 'en', '')}
                                              value={this.state.grape?.description.en || ""}
                                    />
                                </div>

                                <div className="long-field" key="descriptionZh">
                                    <label>Description in Chinese</label>
                                    <textarea type="text"
                                              onChange={(e) => this.handleChange(e.target.value, 'description', 'zh', '')}
                                              value={this.state.grape?.description.zh || ""}
                                    />
                                </div>
                            </div>

                            <div className="fields clearfix">
                                <h3>Appellations</h3>
                                {
                                    this.state.appellations?.map((appellation, index) => (
                                        <div className="field">
                                            <div className="image-wrap">
                                                <label>{'Appellation ' + (index + 1)}</label>
                                                <div className="image-icon-wrap" style={{border: 'none'}}>
                                                    <select
                                                        onChange={(e) => this.updateArray(e, 'appellations', index)}
                                                        value={appellation}>
                                                        {
                                                            this.state.appellationOptions?.map(option =>
                                                                <option value={option.key}>{option.label}</option>)
                                                        }
                                                    </select>
                                                    <i className="icon-trash" onClick={() => this.removeAppellation(index)}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <div className="field" onClick={this.newAppellation}>
                                    <div className="dropzone image-wrap">
                                        <div className="image-icon-wrap icon-add">
                                            <p>Add new appellation</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="actions clearfix below-form">
                        <button onClick={() => this.deleteEntity()} className="red">Delete grape</button>
                    </div>
                </Content>
            </>
    }
}

export default GrapeEdit