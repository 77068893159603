import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'
import FileHandler from '../layout/file-handler/fileHandler'

class FoodPairingNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Food pairings', path: '/food-pairings'},
            tempCover: null,
            foodPairing: {
                name: {
                    en: null,
                    zh: null
                },
                wine: {
                    en: null,
                    zh: null
                },
            }
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }


    createCorrectCoverUrl = (image) => {
        let urlPrefix = 'storage/food-pairing-cover/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    // Cover image
    showCoverImage = (file, fileObj, type, index) => {
        var foodPairing = this.state.foodPairing
        foodPairing.cover = this.createCorrectCoverUrl(fileObj)

        this.setState(previousState => ({
            foodPairing,
            tempCover: foodPairing.cover
        }))
    }

    removeCoverImage = () => {
        this.setState(previousState => ({
            foodPairing: {
                ...this.state.foodPairing,
                cover: null
            },
            tempCover: null
        }))
    }

    handleChange = (e, element, subElement, lang, index = 0) => {
        var newValue = e
        let {foodPairing} = this.state

        if (element === 'content') {
            foodPairing[element][index][subElement][lang] = newValue
        } else {
            if (lang == '') {
                foodPairing[element][subElement] = newValue
            } else {
                foodPairing[element][subElement][lang] = newValue
            }
        }

        this.setState(previousState => ({
            ...previousState,
            foodPairing
        }))
    }

    updateField(e, key) {
        this.setState({
            foodPairing: {
                ...this.state.foodPairing,
                [key]: e.target.value
            }
        })
    }

    saveEntity = () => {
        this.setState({loading: true})

        if (!this.state.foodPairing.name.en || !this.state.foodPairing.name.zh) {
            toast.error('Food pairing\'s name can not be empty')
            this.setState({loading: false})
            return
        } else if (!this.state.foodPairing.cover) {
            toast.error('Food pairing\'s cover can not be empty')
            this.setState({loading: false})
            return
        } else if (!this.state.foodPairing.wine?.en || !this.state.foodPairing.wine?.zh) {
            toast.error('Food pairing\'s related wine can not be empty')
            this.setState({loading: false})
            return
        }

        let cover = this.state.foodPairing.cover
        cover.uri = cover.uri.replace('storage/food-pairing-cover/', '')

        this.setState({
            foodPairing: {
                ...this.state.foodPairing,
                cover: cover,
            }
        })

        const newFoodPairing = this.state.foodPairing

        API.create('food-pairings', newFoodPairing).then((result) => {
            toast.success('Food pairing has been created')
            this.props.history.push('/food-pairings')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Add food pairing'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <form onSubmit={(e) => {
                        this.onSubmit(e, () => this.saveEntity(this.state.foodPairing))
                    }} className="custom-form">
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/food-pairings'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block above">
                            <div className="fields container clearfix">
                                <h3>Information</h3>
                                <div className="field" key="nameEn">
                                    <label>Name in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'en', '')}
                                           value={this.state.foodPairing?.name?.en || ""}/>
                                </div>
                                <div className="field" key="nameZh">
                                    <label>Name in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'zh', '')}
                                           value={this.state.foodPairing?.name?.zh || ""}/>
                                </div>
                                <div className="field" key="cover">
                                    <div className="file-handler-container">
                                        {this.state.tempCover &&
                                        <FileHandler
                                            image={this.state.tempCover.uri}
                                            name={this.state.tempCover.name}
                                            removeImage={(type) => this.removeCoverImage(type)}
                                            type={'images'}
                                            label={'Cover'}
                                            fileType={'images'}
                                            path={'food-pairing-cover'}
                                        />
                                        }

                                        {!this.state.tempCover &&
                                        <FileHandler
                                            showImage={this.showCoverImage}
                                            type={'images'}
                                            label={'Cover'}
                                            fileType={'images'}
                                            path={'food-pairing-cover'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Related wine</h3>
                                <div className="field" key="wineEn">
                                    <label>Wine in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'wine', 'en', '')}
                                           value={this.state.foodPairing?.wine?.en || ""}/>
                                </div>
                                <div className="field" key="wineZh">
                                    <label>Wine in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'wine', 'zh', '')}
                                           value={this.state.foodPairing?.wine?.zh || ""}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </Content>
            </>
    }
}

export default FoodPairingNew