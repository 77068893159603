import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'
import FileHandler from '../layout/file-handler/fileHandler'

class AppellationNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Appellations', path: '/appellations'},
            tempBanner: null,
            tempThumbnail: null,
            tempMap: null,
            appellation: {
                name: {
                    en: null,
                    zh: null
                },
                title: {
                    en: null,
                    zh: null
                },
                description: {
                    en: null,
                    zh: null
                },
                overview: {
                    title: {
                        en: null,
                        zh: null
                    },
                    description: {
                        en: null,
                        zh: null
                    },
                },
                data: {
                    commune: {
                        en: null,
                        zh: null
                    },
                    altitude: {
                        en: null,
                        zh: null
                    },
                    soil: {
                        en: null,
                        zh: null
                    },
                    surfaceArea: {
                        en: null,
                        zh: null
                    },
                    lieuxDits: {
                        en: null,
                        zh: null
                    },
                },
                characteristics: {
                    tasting: {
                        en: null,
                        zh: null
                    },
                    occasion: {
                        en: null,
                        zh: null
                    },
                    nose: {
                        en: null,
                        zh: null
                    },
                    suggestion: {
                        en: null,
                        zh: null
                    },
                    colour: {
                        en: null,
                        zh: null
                    },
                    servingTemperature: {
                        en: null,
                        zh: null
                    },
                    palate: {
                        en: null,
                        zh: null
                    },
                },
                learnMore: []
            }
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    createCorrectBannerUrl = (image) => {
        let urlPrefix = 'storage/appellation-banner/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    // Banner image
    showBannerImage = (file, fileObj, type, index) => {
        var appellation = this.state.appellation
        appellation.banner = this.createCorrectBannerUrl(fileObj)

        this.setState(previousState => ({
            appellation,
            tempBanner: appellation.banner
        }))
    }

    removeBannerImage = () => {
        this.setState(previousState => ({
            appellation: {
                ...this.state.appellation,
                banner: null
            },
            tempBanner: null
        }))
    }


    createCorrectThumbnailUrl = (image) => {
        let urlPrefix = 'storage/appellation-thumbnail/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    // Thumbnail image
    showThumbnailImage = (file, fileObj, type, index) => {
        var appellation = this.state.appellation
        appellation.thumbnail = this.createCorrectThumbnailUrl(fileObj)

        this.setState(previousState => ({
            appellation,
            tempThumbnail: appellation.thumbnail
        }))
    }

    removeThumbnailImage = () => {
        this.setState(previousState => ({
            appellation: {
                ...this.state.appellation,
                thumbnail: null
            },
            tempThumbnail: null
        }))
    }

    createCorrectMapUrl = (image) => {
        let urlPrefix = 'storage/appellation-map/'

        let urlEnd = image.uri
        let url = urlPrefix + urlEnd

        image.uri = url
        return image
    }

    // Map image
    showMapImage = (file, fileObj, type, index) => {
        var appellation = this.state.appellation
        appellation.map = this.createCorrectMapUrl(fileObj)

        this.setState(previousState => ({
            appellation,
            tempMap: appellation.map
        }))
    }

    removeMapImage = () => {
        this.setState(previousState => ({
            appellation: {
                ...this.state.appellation,
                map: null
            },
            tempMap: null
        }))
    }


    // Content block
    addContentBlock = () => {
        const learnMore = this.state.appellation.learnMore

        const newBlock = {
            title: {
                en: '',
                zh: ''
            },
            description: {
                en: '',
                zh: ''
            },
        }

        const newBlocks = [...learnMore, newBlock]

        this.setState({
            appellation: {
                ...this.state.appellation,
                learnMore: newBlocks
            }
        })
    }

    removeContentBlock = (index) => {
        var r = window.confirm("Are you sure you want to delete this block?");
        if (r === false) return

        let learnMore = this.state.appellation.learnMore

        learnMore.splice(index, 1)

        this.setState(prevState => ({
            ...prevState,
            learnMore
        }))
    }

    handleChange = (e, element, subElement, lang, index = 0) => {
        var newValue = e
        let {appellation} = this.state

        if (element === 'learnMore') {
            appellation[element][index][subElement][lang] = newValue
        } else {
            if (lang == '') {
                appellation[element][subElement] = newValue
            } else {
                appellation[element][subElement][lang] = newValue
            }
        }

        this.setState(previousState => ({
            ...previousState,
            appellation
        }))
    }

    updateField(e, key) {
        this.setState({
            appellation: {
                ...this.state.appellation,
                [key]: e.target.value
            }
        })
    }

    saveEntity = () => {
        this.setState({loading: true})

        if (!this.state.appellation.name.en || !this.state.appellation.name.zh) {
            toast.error('Appellation\'s name can not be empty')
            this.setState({loading: false})
            return
        } else if (!this.state.appellation.banner) {
            toast.error('Appellation\'s banner can not be empty')
            this.setState({loading: false})
            return
        } else if (!this.state.appellation.thumbnail) {
            toast.error('Appellation\'s thumbnail can not be empty')
            this.setState({loading: false})
            return
        } else if (!this.state.appellation.map) {
            toast.error('Appellation\'s map can not be empty')
            this.setState({loading: false})
            return
        }

        let banner = this.state.appellation.banner
        banner.uri = banner.uri.replace('storage/appellation-banner/', '')

        let thumbnail = this.state.appellation.thumbnail
        thumbnail.uri = thumbnail.uri.replace('storage/appellation-thumbnail/', '')

        let map = this.state.appellation.map
        map.uri = map.uri.replace('storage/appellation-map/', '')

        this.setState({
            appellation: {
                ...this.state.appellation,
                banner: banner,
                thumbnail: thumbnail,
                map: map,
            }
        })

        const newAppellation = this.state.appellation

        API.create('appellations', newAppellation).then((result) => {
            toast.success('Appellation has been created')
            this.props.history.push('/appellations')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Add appellation'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <form onSubmit={(e) => {
                        this.onSubmit(e, () => this.saveEntity(this.state.appellation))
                    }} className="custom-form">
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/appellations'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block above">
                            <div className="fields container clearfix">
                                <h3>Information</h3>
                                <div className="field" key="nameEn">
                                    <label>Name in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'en', '')}
                                           value={this.state.appellation?.name?.en || ""}/>
                                </div>
                                <div className="field" key="nameZh">
                                    <label>Name in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'name', 'zh', '')}
                                           value={this.state.appellation?.name?.zh || ""}/>
                                </div>

                                <div className="field" key="titleEn">
                                    <label>Title in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'title', 'en', '')}
                                           value={this.state.appellation?.title?.en || ""}/>
                                </div>
                                <div className="field" key="titleZh">
                                    <label>Title in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'title', 'zh', '')}
                                           value={this.state.appellation?.title?.zh || ""}/>
                                </div>
                                <div className="wide-field" key="descriptionEn">
                                    <label>Description in English</label>
                                    <textarea type="text"
                                              onChange={(e) => this.handleChange(e.target.value, 'description', 'en', '')}
                                              value={this.state.appellation?.description?.en || ""}/>
                                </div>
                                <div className="wide-field" key="descriptionZh">
                                    <label>Description in Chinese</label>
                                    <textarea type="text"
                                              onChange={(e) => this.handleChange(e.target.value, 'description', 'zh', '')}
                                              value={this.state.appellation?.description?.zh || ""}/>
                                </div>

                                <div className="field" key="banner">
                                    <div className="file-handler-container">
                                        {this.state.tempBanner &&
                                        <FileHandler
                                            image={this.state.tempBanner.uri}
                                            name={this.state.tempBanner.name}
                                            removeImage={(type) => this.removeBannerImage(type)}
                                            type={'images'}
                                            label={'Banner'}
                                            fileType={'images'}
                                            path={'appellation-banner'}
                                        />
                                        }

                                        {!this.state.tempBanner &&
                                        <FileHandler
                                            showImage={this.showBannerImage}
                                            type={'images'}
                                            label={'Banner'}
                                            fileType={'images'}
                                            path={'appellation-banner'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>
                                <div className="field" key="thumbnail">
                                    <div className="file-handler-container">
                                        {this.state.tempThumbnail &&
                                        <FileHandler
                                            image={this.state.tempThumbnail.uri}
                                            name={this.state.tempThumbnail.name}
                                            removeImage={(type) => this.removeThumbnailImage(type)}
                                            type={'images'}
                                            label={'Thumbnail'}
                                            fileType={'images'}
                                            path={'appellation-thumbnail'}
                                        />
                                        }

                                        {!this.state.tempThumbnail &&
                                        <FileHandler
                                            showImage={this.showThumbnailImage}
                                            type={'images'}
                                            label={'Thumbnail'}
                                            fileType={'images'}
                                            path={'appellation-thumbnail'}
                                            dropzone
                                        />
                                        }
                                    </div>
                                </div>
                                <div className="field" key="map">
                                    <div className="file-handler-container">
                                        {this.state.tempMap &&
                                            <FileHandler
                                                image={this.state.tempMap.uri}
                                                name={this.state.tempMap.name}
                                                removeImage={(type) => this.removeMapImage(type)}
                                                type={'images'}
                                                label={'Map'}
                                                fileType={'images'}
                                                path={'appellation-map'}
                                            />
                                        }

                                        {!this.state.tempMap &&
                                            <FileHandler
                                                showImage={this.showMapImage}
                                                type={'images'}
                                                label={'Map'}
                                                fileType={'images'}
                                                path={'appellation-map'}
                                                dropzone
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Overview</h3>
                                {/*<div className="field" key="titleEn">*/}
                                {/*    <label>Title in English</label>*/}
                                {/*    <input type="text"*/}
                                {/*           onChange={(e) => this.handleChange(e.target.value, 'overview', 'title', 'en')}*/}
                                {/*           value={this.state.appellation?.overview.title.en || ""}/>*/}
                                {/*</div>*/}
                                {/*<div className="field" key="titleZh">*/}
                                {/*    <label>Title in Chinese</label>*/}
                                {/*    <input type="text"*/}
                                {/*            onChange={(e) => this.handleChange(e.target.value, 'overview', 'title', 'zh')}*/}
                                {/*            value={this.state.appellation?.overview.title.zh || ""}/>*/}
                                {/*</div>*/}
                                <div className="wide-field" key="descriptionEn">
                                    <label>Description in English</label>
                                    <textarea type="text"
                                            onChange={(e) => this.handleChange(e.target.value, 'overview', 'description', 'en')}
                                            value={this.state.appellation?.overview.description.en || ""}/>
                                </div>
                                <div className="wide-field" key="descriptionZh">
                                    <label>Description in Chinese</label>
                                    <textarea type="text"
                                            onChange={(e) => this.handleChange(e.target.value, 'overview', 'description', 'zh')}
                                            value={this.state.appellation?.overview.description.zh || ""}/>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Relative data</h3>
                                <div className="field" key="communeEn">
                                    <label>Commune in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'commune', 'en')}
                                           value={this.state.appellation?.data.commune.en || ""}/>
                                </div>
                                <div className="field" key="communeZh">
                                    <label>Commune in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'commune', 'zh')}
                                           value={this.state.appellation?.data.commune.zh || ""}/>
                                </div>
                                <div className="field" key="altitudeEn">
                                    <label>Altitude in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'altitude', 'en')}
                                           value={this.state.appellation?.data.altitude.en || ""}/>
                                </div>
                                <div className="field" key="altitudeZh">
                                    <label>Altitude in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'altitude', 'zh')}
                                           value={this.state.appellation?.data.altitude.zh || ""}/>
                                </div>
                                <div className="field" key="soilEn">
                                    <label>Soil in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'soil', 'en')}
                                           value={this.state.appellation?.data.soil.en || ""}/>
                                </div>
                                <div className="field" key="soilZh">
                                    <label>Soil in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'soil', 'zh')}
                                           value={this.state.appellation?.data.soil.zh || ""}/>
                                </div>
                                <div className="field" key="surfaceAreaEn">
                                    <label>Surface area in English</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'surfaceArea', 'en')}
                                           value={this.state.appellation?.data.surfaceArea.en || ""}/>
                                </div>
                                <div className="field" key="surfaceAreaZh">
                                    <label>Surface area in Chinese</label>
                                    <input type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'data', 'surfaceArea', 'zh')}
                                           value={this.state.appellation?.data.surfaceArea.zh || ""}/>
                                </div>
                                <div className="wide-field" key="lieuxDitsEn">
                                    <label>Lieux-dits in English</label>
                                    <textarea type="text"
                                            onChange={(e) => this.handleChange(e.target.value, 'data', 'lieuxDits', 'en')}
                                            value={this.state.appellation?.data.lieuxDits.en || ""}
                                    />
                                </div>
                                <div className="wide-field" key="lieuxDitsZh">
                                    <label>Lieux-dits in Chinese</label>
                                    <textarea type="text"
                                            onChange={(e) => this.handleChange(e.target.value, 'data', 'lieuxDits', 'zh')}
                                            value={this.state.appellation?.data.lieuxDits.zh || ""}
                                    />
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Characteristics</h3>
                                <div className="wide-field" key="tastingEn">
                                    <label>Tasting in English</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'tasting', 'en')}
                                           value={this.state.appellation?.characteristics.tasting.en || ""}/>
                                </div>
                                <div className="wide-field" key="tastingZh">
                                    <label>Tasting in Chinese</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'tasting', 'zh')}
                                           value={this.state.appellation?.characteristics.tasting.zh || ""}/>
                                </div>
                                <div className="wide-field" key="occasionEn">
                                    <label>Occasion in English</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'occasion', 'en')}
                                           value={this.state.appellation?.characteristics.occasion.en || ""}/>
                                </div>
                                <div className="wide-field" key="occasionZh">
                                    <label>Occasion in Chinese</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'occasion', 'zh')}
                                           value={this.state.appellation?.characteristics.occasion.zh || ""}/>
                                </div>
                                <div className="wide-field" key="noseEn">
                                    <label>Nose in English</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'nose', 'en')}
                                           value={this.state.appellation?.characteristics.nose.en || ""}/>
                                </div>
                                <div className="wide-field" key="noseZh">
                                    <label>Nose in Chinese</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'nose', 'zh')}
                                           value={this.state.appellation?.characteristics.nose.zh || ""}/>
                                </div>
                                <div className="wide-field" key="suggestionEn">
                                    <label>Culinary suggestion in English</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'suggestion', 'en')}
                                           value={this.state.appellation?.characteristics.suggestion.en || ""}/>
                                </div>
                                <div className="wide-field" key="suggestionZh">
                                    <label>Culinary suggestion in Chinese</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'suggestion', 'zh')}
                                           value={this.state.appellation?.characteristics.suggestion.zh || ""}/>
                                </div>
                                <div className="wide-field" key="colourEn">
                                    <label>Colour in English</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'colour', 'en')}
                                           value={this.state.appellation?.characteristics.colour.en || ""}/>
                                </div>
                                <div className="wide-field" key="colourZh">
                                    <label>Colour in Chinese</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'colour', 'zh')}
                                           value={this.state.appellation?.characteristics.colour.zh || ""}/>
                                </div>
                                <div className="wide-field" key="servingTemperatureEn">
                                    <label>Serving temperature in English</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'servingTemperature', 'en')}
                                           value={this.state.appellation?.characteristics.servingTemperature.en || ""}/>
                                </div>
                                <div className="wide-field" key="servingTemperatureZh">
                                    <label>Serving temperature in Chinese</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'servingTemperature', 'zh')}
                                           value={this.state.appellation?.characteristics.servingTemperature.zh || ""}/>
                                </div>
                                <div className="wide-field" key="palateEn">
                                    <label>Palate in English</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'palate', 'en')}
                                           value={this.state.appellation?.characteristics.palate.en || ""}/>
                                </div>
                                <div className="wide-field" key="palateZh">
                                    <label>Palate in Chinese</label>
                                    <textarea type="text"
                                           onChange={(e) => this.handleChange(e.target.value, 'characteristics', 'palate', 'zh')}
                                           value={this.state.appellation?.characteristics.palate.zh || ""}/>
                                </div>
                            </div>

                            <div className="fields container clearfix">
                                <h3>Learn more</h3>

                                {
                                    Object.keys(this.state.appellation.learnMore).map(index => {
                                        return (
                                            <div className="fields container clearfix">
                                                <div>
                                                    <p style={{
                                                        textDecoration: 'underline'
                                                    }}>{parseInt(index) + 1}. Block {parseInt(index) + 1}
                                                    </p>
                                                    <label style={{
                                                        textDecorationLine: 'underline',
                                                        color: '#2196F3',
                                                        position: 'absolute',
                                                        right: '32px',
                                                        cursor: 'pointer'
                                                    }}
                                                           onClick={() => {
                                                               this.removeContentBlock(index)
                                                           }}
                                                    >Delete
                                                    </label>
                                                </div>

                                                <div className="field" key="titleEn">
                                                    <label>Title in English</label>
                                                    <input type="text"
                                                           onChange={(e) => this.handleChange(e.target.value, 'learnMore', 'title', 'en', index)}
                                                           value={this.state.appellation?.learnMore[index].title.en || ""}/>
                                                </div>

                                                <div className="field" key="titleZh">
                                                    <label>Title in Chinese</label>
                                                    <input type="text"
                                                        onChange={(e) => this.handleChange(e.target.value, 'learnMore', 'title', 'zh', index)}
                                                        value={this.state.appellation?.learnMore[index].title.zh || ""}/>
                                                </div>

                                                <div className="long-field" key="descriptionEn">
                                                    <label>Description in English</label>
                                                    <textarea type="text"
                                                        onChange={(e) => this.handleChange(e.target.value, 'learnMore', 'description', 'en', index)}
                                                        value={this.state.appellation?.learnMore[index].description.en || ""}
                                                    />
                                                </div>

                                                <div className="long-field" key="descriptionZh">
                                                    <label>Description in Chinese</label>
                                                    <textarea type="text"
                                                        onChange={(e) => this.handleChange(e.target.value, 'learnMore', 'description', 'zh', index)}
                                                        value={this.state.appellation?.learnMore[index].description.zh || ""}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="buttons">
                                    <button className="blue add clearfix"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.addContentBlock()
                                            }}>
                                        Add new block
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Content>
            </>
    }
}

export default AppellationNew