import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import Header from './header'
import Login from '../account/login'

import PrivateRoute from './privateRoute'
import DB from '../../services/db'

/* Users */
import UserList from '../users/list.jsx'
import UserNew from '../users/new.jsx'
import UserEdit from '../users/edit.jsx'

/* Appellations */
import AppellationList from "../appellations/list.jsx"
import AppellationNew from "../appellations/new.jsx"
import AppellationEdit from "../appellations/edit.jsx"

/* Colours */
import ColourList from "../colours/list.jsx"
import ColourNew from "../colours/new.jsx"
import ColourEdit from "../colours/edit.jsx"

/* Food pairings */
import FoodPairingList from "../food-pairings/list.jsx"
import FoodPairingNew from "../food-pairings/new.jsx"
import FoodPairingEdit from "../food-pairings/edit.jsx"

/* Grapes */
import GrapeList from "../grapes/list.jsx"
import GrapeNew from "../grapes/new.jsx"
import GrapeEdit from "../grapes/edit.jsx"

/* Introductions */
import IntroductionList from "../introductions/list.jsx"
import IntroductionNew from "../introductions/new.jsx"
import IntroductionEdit from "../introductions/edit.jsx"

/* News */
import NewsList from "../news/list.jsx"
import NewsNew from "../news/new.jsx"
import NewsEdit from "../news/edit.jsx"

/* Taste advices */
import TasteAdviceList from "../taste-advices/list.jsx"
import TasteAdviceNew from "../taste-advices/new.jsx"
import TasteAdviceEdit from "../taste-advices/edit.jsx"

function Layout() {
    let role = DB.get('user') ? DB.get('user').role : ''
    return (
        <Router>
            <Header/>
            <main id="main">
                <ToastContainer position="top-right"/>
                <Switch>
                    <Route exact path="/login" component={Login}/>

                    <PrivateRoute exact path="/users" component={UserList}/>
                    <PrivateRoute exact path="/users/new" component={UserNew}/>
                    <PrivateRoute exact path="/users/:userId/edit" component={UserEdit}/>

                    <PrivateRoute exact path="/appellations" component={AppellationList}/>
                    <PrivateRoute exact path="/appellations/new" component={AppellationNew}/>
                    <PrivateRoute exact path="/appellations/:appellationId/edit" component={AppellationEdit}/>

                    <PrivateRoute exact path="/colours" component={ColourList}/>
                    <PrivateRoute exact path="/colours/new" component={ColourNew}/>
                    <PrivateRoute exact path="/colours/:colourId/edit" component={ColourEdit}/>

                    <PrivateRoute exact path="/food-pairings" component={FoodPairingList}/>
                    <PrivateRoute exact path="/food-pairings/new" component={FoodPairingNew}/>
                    <PrivateRoute exact path="/food-pairings/:foodPairingId/edit" component={FoodPairingEdit}/>

                    <PrivateRoute exact path="/grapes" component={GrapeList}/>
                    <PrivateRoute exact path="/grapes/new" component={GrapeNew}/>
                    <PrivateRoute exact path="/grapes/:grapeId/edit" component={GrapeEdit}/>

                    <PrivateRoute exact path="/introductions" component={IntroductionList}/>
                    <PrivateRoute exact path="/introductions/new" component={IntroductionNew}/>
                    <PrivateRoute exact path="/introductions/:introductionId/edit" component={IntroductionEdit}/>

                    <PrivateRoute exact path="/news" component={NewsList}/>
                    <PrivateRoute exact path="/news/new" component={NewsNew}/>
                    <PrivateRoute exact path="/news/:newsId/edit" component={NewsEdit}/>

                    <PrivateRoute exact path="/taste-advices" component={TasteAdviceList}/>
                    <PrivateRoute exact path="/taste-advices/new" component={TasteAdviceNew}/>
                    <PrivateRoute exact path="/taste-advices/:tasteAdviceId/edit" component={TasteAdviceEdit}/>

                    {role == 'admin' &&
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                <Redirect to="/users"/>
                            )
                        }}
                    />
                    }
                    {role != 'admin' &&
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                <Redirect to="/events"/>
                            )
                        }}
                    />
                    }
                </Switch>
            </main>
        </Router>
    )
}

export default Layout
